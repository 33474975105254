import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Cursor from '../components/Cursor';
import SeoBasic from '../components/SeoBasic';
import Colophon from '../components/Colophon';
import { gsap } from 'gsap';

const Contatti = ({ transitionStatus }) => {
  useEffect(() => {
    if (transitionStatus) {
      // console.log('animate', transitionStatus);
    }
    if (transitionStatus === 'exiting') {
      gsap.to('.contatti', { delay: 0.1, duration: 0.8, opacity: 0 });
    }
    if (transitionStatus === 'entering') {
      gsap.fromTo(
        '.contatti',
        { opacity: '0' },
        { delay: 0, duration: 0.1, opacity: '1' }
      );
      gsap.fromTo(
        '.contatti',
        { x: '-100%' },
        { delay: 0, duration: 0.8, x: 0, ease: 'back' }
      );
    }
  }, [transitionStatus]);
  const [mailSent, setmailSent] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({});
  const [sending, setSending] = useState(false);

  const handleChange = (e, field) => {
    let value = e.target.value;
    setFormData({
      ...formData,
      [field]: value,
    });
  };
  const handleFormSubmit = (e) => {
    setError();
    setmailSent();
    e.preventDefault();
    setSending(true);

    fetch('https://www.popland.it/reactmail.php', {
      method: 'POST',
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => handleResponse(data));
  };

  const handleResponse = (data) => {
    console.log(data);
    if (data.sent === true) {
      setmailSent(true);
    } else {
      setError(true);
    }
    setSending(false);
  };
  return (
    <>
      {sending && (
        <div
          id="loading"
          className="w-screen flex-col fixed overflow-auto pt-2 pb-10 h-screen  bg-gray-800  top-0 left-0 z-50 bg-opacity-90"
        ></div>
      )}
      <main className="container mx-auto px-2 md:px-0">
        <SeoBasic slug="home" />
        <Header />
        <Cursor />
        {sending && <>SENDING</>}
        <div className="w-full h-full flex justify-center items-center contatti opacity-0">
          <form action="#">
            <div className="flex justify-between gap-3">
              <span className="w-1/2">
                <label
                  htmlFor="firstname"
                  className="block text-xs font-semibold text-gray-600 uppercase"
                >
                  Firstname
                </label>
                <input
                  id="firstname"
                  type="text"
                  name="firstname"
                  placeholder="Il tuo nome"
                  autoComplete="given-name"
                  className="block w-full p-3 mt-2 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                  required
                  onChange={(e) => handleChange(e, 'firstname')}
                />
              </span>
              <span className="w-1/2">
                <label
                  htmlFor="lastname"
                  className="block text-xs font-semibold text-gray-600 uppercase"
                >
                  Lastname
                </label>
                <input
                  id="lastname"
                  type="text"
                  name="lastname"
                  placeholder="Cognome"
                  autoComplete="family-name"
                  className="block w-full p-3 mt-2 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                  required
                  onChange={(e) => handleChange(e, 'lastname')}
                />
              </span>
            </div>
            <label
              htmlFor="email"
              className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              E-mail
            </label>
            <input
              id="email"
              type="email"
              name="email"
              placeholder="nome@email"
              autoComplete="email"
              className="block w-full p-3 mt-2 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
              required
              onChange={(e) => handleChange(e, 'email')}
            />

            <label
              htmlFor="message"
              className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Messaggio
            </label>
            <textarea
              id="about"
              name="about"
              rows="3"
              className="block w-full p-3 mt-2 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
              placeholder="il tuo messaggio"
              onChange={(e) => handleChange(e, 'message')}
            ></textarea>

            <button
              type="submit"
              disabled={sending}
              className="w-full py-3 mt-6 font-medium tracking-widest text-white uppercase bg-black shadow-lg focus:outline-none hover:bg-gray-900 hover:shadow-none"
              onClick={(e) => handleFormSubmit(e)}
            >
              invia
            </button>
            {error && (
              <div className="block mt-2 text-xs font-semibold text-gray-600 uppercase">
                Si è verificato un errore, ti preghiamo di provare di nuovo
              </div>
            )}
            {mailSent && (
              <div className="block mt-2 text-xs font-semibold text-gray-600 uppercase">
                Il tuo messaggio è stato inviato correttamente
              </div>
            )}
          </form>
        </div>
        <Colophon />
      </main>
    </>
  );
};

export default Contatti;
